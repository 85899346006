export const environment = {
  production: true,
  env: 'production',
  defaultServeDomain: 'tpxr.in',
  apiUrl: 'https://api.tapxreality.com/',
  qrAPIKey: 'ocknsniphzpetujxeuepuwpmczxhnfneqmijaugz',
  clientKey: 'RvebQSKC0rNoleQ6ZqAJ0bLqpOHx86WNEfRyOtrh',
  clientSecret: 'VP1DQngWUKF0dWBDbZKUFoO9f4gqcoqyIF7apvILTaiHYOayQIGvBk06qLWGUwS3jR0hQuYK5k7XLw6vQKB30s4zp9AB6REsNqcPn3z60UgXoSoCjaS9LiHlamvjsXdU',
  stripePublishableKey: 'pk_test_51JBeMeSJ2CHFsg9ozW0IzHXs7sIpqeCeTQFQMlWxgMPC0n6w3czVUGS2jjKnDIh71KqKtFBkcbZZKDXpM2M4wsW9004h9Vpheo',
  demoBookUrl: 'https://calendly.com/tapxreality/30min',
  googleMapAPIKey: 'AIzaSyB5S6rCWR14wWr4EvbOSReZQZeyEzSoPVE',
  planPricing: {
    lite: {
      monthly: '5cc7c2fb-0a29-4d23-bb9d-6377f29b3a97',
      yearly: 'e80359c3-89c0-4eef-ad31-d203f82da531'
    },
    standard: {
      monthly: '0b7b738c-fdb2-4105-883a-26fc5866bce2',
      yearly: '040ad2f2-fc6a-43ea-b25c-62a123c6efd1',
    },
    pro: {
      monthly: 'b97c67ce-d310-4cd9-b313-8d377f9258e6',
      yearly: 'e1b21d95-7847-4d3b-8946-64fc9d07dd28'
    },
    enterprise: {
      monthly: null,
      yearly: null
    },
    free: {
      monthly: '1',
      yearly: '1'
    }
  }
};
